import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "./CardCarousel.css";

export default function CardCarousel() {
  const settings = {
    dots: 3,
    infinite: 1,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="cardCarouselBackgrondImage">
        <Container className="cardCarouselItem">
          <Slider {...settings}>
            <div className="partnerNameStyle">
                Our Partners <span className="slashStyle"></span>
            </div>
            <div>
            <img className="cardImgaes" style={{marginLeft: "27%"}} src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/M-tech.svg?alt=media&token=96be5352-e34b-4e41-a098-9912d3df6d07" alt="Img" />
            </div>
            <div>
            <img className="cardImgaes cardImgesCarousel1" style={{marginLeft: "27%"}} src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/UzTea.svg?alt=media&token=a427aaf5-79b2-476f-bfdc-a8fafde20f24" alt="Img" />
            </div>
            <div>
            <img className="cardImgaes" style={{marginLeft: "27%"}} src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/Raled.svg?alt=media&token=bed6d923-881a-4e57-ba60-84ef91ccb759" alt="Img" />
            </div>
            <div>
              <img className="cardImgaes cardImgesCarousel2" style={{marginLeft: "27%"}} src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/ETF.svg?alt=media&token=af759db8-b45a-41de-a5cf-e73d537a6f5c" alt="Img" />
            </div>
          </Slider>
        </Container>
      </div>
    </div>
  );
}
