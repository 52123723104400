import {
    createSlice
} from "@reduxjs/toolkit"

const initialState = {
    uploadFile: null,
    data: []
}

export const applySlice = createSlice({
    name: 'applyData',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        },
        uploadFileAction: (state, action) => {
            state.uploadFile = action.payload;
        }
    },
})

export const {
    uploadFileAction,
    setData
} = applySlice.actions;

export default applySlice.reducer;