import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import PropTypes from 'prop-types'
import Close from '../Assets/close.png'

import ReactCardFlip from 'react-card-flip'

const CardTitle = ({ posts }) => {
  const [state, setState] = useState({ isFlipped: false })
  const handleClick = (event) => {
    event.preventDefault()
    setState((prevState) => ({ isFlipped: !prevState.isFlipped }))
  }

  return (
    <>
      <ReactCardFlip isFlipped={state.isFlipped} infinite>
        <Card className="cardBodyInExpertsPage">
          <Card.Img
            className="cardInsideImgStyleExperts"
            variant="top"
            src={posts.Pictures}
          />
          <Card.Body>
            <Card.Title onClick={handleClick} className="expertsNameTextStyle">
              {posts.Titile}
            </Card.Title>
            <Card.Text className="expertsDescriptionTextStyle">
              {posts.Level}
              <br />
              {posts.Level2}
            </Card.Text>
            {posts.LinkedinLink ? (
              <a className="linkedinStyleInExperts" href={posts.LinkedinLink}>
                <img
                  className="expertsLinkedin"
                  src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/Linkedin.svg?alt=media&token=d6249986-f578-4ad6-995e-65e5733141a7"
                  alt="Img"
                />
              </a>
            ) : (
              ''
            )}
          </Card.Body>
        </Card>

        <Card className="cardBodyInExpertsPage2">
          <p onClick={handleClick}>
            <img className="closeTheCardInExpert" src={Close} alt="" />
          </p>
          <Card.Body>
            <Card.Title className="expertsNameTextStyleSecondWay">
              {posts.Titile}
            </Card.Title>
            <Card.Text
              className="expertsDescriptionTextStyleSecondWay scrollspy-example"
              data-bs-spy="scroll"
              data-bs-target="#list-example"
              data-bs-offset="0"
              tabIndex="0"
            >
              {posts.Description}
              <br />
              <br />
              {posts.Description2}
            </Card.Text>
          </Card.Body>
        </Card>
      </ReactCardFlip>
    </>
  )
}

export default CardTitle
