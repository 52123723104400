import React from 'react';
import './Contact.css';

export default function Contact() {
  return (
    <div className="contactWrapper">
      <div className="container">
        <p className='contactBigTitle'>Contact Mahorat & Management</p>
        <p className='contactSmallTitle'>We appreciate your interest in us.</p>
        <div className="row contactCallSectionStyle">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <img src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/call.svg?alt=media&token=212af669-6fb4-426a-a873-3c8226fa6282" alt="Img" />
            <p className='callBigTextStyle'>Phone number</p>
              <p className='callSmallTextStyle'>+998 95 195-00-55</p>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
            <img src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/email.svg?alt=media&token=8cb5ed9c-bb97-4820-9f60-8e4e24450b78" alt="Img" />
            <p className='callBigTextStyle'>Email address</p>
            <a className='emailStyleSend' href="mailto:info@mahorat.org">
              <p className='callSmallTextStyle emailStyleSend'>info@mahorat.org</p>
            </a>
            <a className='emailStyleSend' href="mailto:mahoratmanagement@gmail.com">
            <p className='callSmallTextStyle emailStyleSend'>mahoratmanagement@gmail.com</p>            </a>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
            <img src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/Location.svg?alt=media&token=3a4f945a-c7e0-417d-958b-cf17687c197b" alt="Img" />
            <p className='callBigTextStyle'>Address</p>
            <p className='callSmallTextStyle'>Shota Rustavili Street 53B </p>
            <p className='callSmallTextStyle'>Tashkent, Uzbekistan 100003</p>
          </div>
        </div>
      </div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5996.346567646579!2d69.251601!3d41.283333!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x672cf1e7743ccd33!2zNDHCsDE3JzAwLjAiTiA2OcKwMTUnMDUuOCJF!5e0!3m2!1sen!2s!4v1658926045086!5m2!1sen!2s"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}
