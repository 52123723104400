import React from 'react';
import SricleImg from '../Assets/AboutSircle.svg';
import CardCarousel from '../CardCarousel/CardCarousel';
import './About.css';

export default function About() {
    return (
        <div className='aboutWrapper'>
            <div className="container">
                <p className='aboutEnterText'>WHO WE ARE</p>

                <div className='smallTextAbout'>
                    Mahorat & Management was founded by a group of expert-initiators on professional education as an independent <br /> research and training center to support HRD - Human Resource Development.

                    <p className="insodeAboutSmallText">
                        We are non-governmental educational organization that has been operating since 2015 in the field of adults training, <br /> ensuring the quality of education and introducing international standards to local educational process, including the <br /> introduction of advanced forms of training and retraining using information technologies, improving curriculum and <br /> materials based on the widespread use of modern  teaching methods.
                    </p>

                    <div className="insodeAboutSmallText">
                        Mahorat & Management actively cooperates with governmental and non-governmental organizations, and implements <br /> short and long term educational projects. 
                    </div>
                </div>
            </div>
            <div className="aboutImgSection">
                <div className="container">
                    <p className='aboutImgSectionText'>
                        Our mission is to unite and build the <br />
                        capacity of experts in order to improve the <br />
                        mechanisms and systems of vocational <br /> education,
                        based on international quality <br />
                        indicators and the requirements of the <br />
                        international labor market.
                    </p>
                </div>
            </div>
            <div className="aboutThirdSection container">
                <p className="thirdSectionAboutBigText">We aim towards realizing the following key components:</p>

                <div className="SircleImgText">
                    <span><img src={SricleImg} alt="Img" /></span> <span>
                        educational - to develop high-quality education, to assist the professional development of managers and teachers, <br /> professional development and retraining of highly qualified specialists based on the principle of LLL;
                    </span>
                </div>
                <div className="SircleImgText">
                    <span><img src={SricleImg} alt="Img" /></span> <span>
                        cultural - to be a cultural educational center, contributing to a change in the social environment, creating a certain <br /> spiritual atmosphere, teaching intelligent and constructive problem solving;
                    </span>
                </div>
                <div className="SircleImgText">
                    <span><img src={SricleImg} alt="Img" /></span> <span>
                        innovative - to provide quality education in accordance with the UNESCO Concept "Education - 2030" and SDG 4 <br /> "Quality Education" with the use of new methods and technologies in retraining and advanced training of specialists, <br /> developing innovative thinking for people over 18 years old.
                    </span>
                </div>
                <div className="SircleImgText">
                    <span><img src={SricleImg} alt="Img" /></span> <span>
                        constructive- we build a constructive partnership with the local governmental and nongovernmental organizations <br /> and individuals according to their interest in a partnership in HRD.
                    </span>
                </div>

                <p className='aboutEnterText2'>HOW WE WORK</p>

                <p className='howWeWorkAllText'>
                    Our strategy for sustainable development is based on the UNESCO Declaration - Education 2030: Achieving <br /> Inclusive and Equitable Quality Education and Lifelong Learning for All.  In order to realize this strategy the <br /> organization regularly runs following projects:
                </p>
            </div>

            <div className="sectionWithGridAbout">
                <div className="container gridSectionInsideStyle">
                    <div className="row">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <p className="gridSmallAllText">
                                Participation in tenders for social order to improve the systems of vocational education and training at all levels (ISCED and ISCO):  
                            </p> 
                            <div className="gridSmallAllText">
                                <ul>
                                    <li>
                                        Management of the educational process
                                    </li>
                                    <li>
                                        Recognition of prior learning (RPL)
                                    </li>
                                    <li>
                                        Systematization, automation and launching of educational processes and systems.
                                    </li>
                                    <div className="bottomStyleText gridSmallAllText">
                                        Organization of exchange programs between educators, researchers, and supervisors.
                                    </div>
                              </ul>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <img className="aboutLineImgStyle" src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/AboutLineImg.svg?alt=media&token=1ca9e8ef-377d-434b-bebe-02f3054ea85f" alt="Img" />
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <p className='gridInsieFirstText'>
                                Outsourcing services in certain areas of vocational education and training (VET) and professional development and retraining (CPD-Continuing professional development). RPL - Recognition of Prior Learning.
                            </p>
                            <p className='gridInsideSecondText gridInsieFirstText'>
                                Support for youth initiatives aimed at the welfare of the population, by building the capacity of human capital. <br />
                                <img className='aboutDubleImg' src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/AboutSmalImages.svg?alt=media&token=0673c306-db7b-4419-ba52-c5c1fcdc93e1" alt="" />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cardCarouselAbout">
                <CardCarousel />
            </div>
        </div>
    )
}
