import React from 'react';
import './Footer.css';
import Facebook from '../Assets/Facebook.svg'
import Instagram from '../Assets/Instagram.svg'
import Telegram from '../Assets/Telegram.svg'
import YouTube from '../Assets/youtube.svg'
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className='footer-container'>
      <div className="container-fluid">
        <div className="row mobileDisableFooter">
          <div className="col-sm-4 col-md-3 col-lg-3 footerLogoAndSocialMediaStyle">
            <img className="footerLogoStyle" src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/footerLogo.svg?alt=media&token=c7017761-8945-434c-9a5d-508efe8debe2" alt="Img" /> <br className='brActiveFooter' />
            <a href="https://www.facebook.com/watch/mahoratLC/">
              <img className='footerSocialMedia socialMedia' src={Facebook} alt="Img" />
            </a>
            <a href="https://www.instagram.com/mahoratmanagement/">
              <img className='footerSocialMedia' src={Instagram} alt="Img" />
            </a>
            <a href="https://t.me/mahorat_management">
              <img className='footerSocialMedia' src={Telegram} alt="IMg" />
            </a>
            <a href="https://www.youtube.com/channel/UC5a9BbxxGgeOJaZ2wdA9-xg">
              <img className='footerSocialMedia' src={YouTube} alt="IMg" />
            </a>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 fotterclmdWIdthStyle">
            <div className="container">
              <div className="row allFooterLink">
                <div className="col-sm-3 col-md-3 col-lg3">
                  <Link to='/About' className='footerLinks'>About us</Link>
                </div>
                <div className="col-sm-3 col-md-3 col-lg3">
                  <Link to='/Services' className='footerLinks'>Our Services</Link>
                </div>
                <div className="col-sm-3 col-md-3 col-lg3">
                  <Link to='/Apply' className='footerLinks applyFooterLink'>Apply</Link>
                </div>
                <div className="col-sm-3 col-md-3 col-lg3">
                  <Link to='/Contact' className='footerLinks'>Contact</Link>
                </div>
                <div className="col-sm-3 col-md-6 col-lg3">
                  <p className='footerLinks fLink1'>Privacy Policy</p>
                </div>
                <div className="col-sm-3 col-md-5 col-lg3">
                  <p className='footerLinks fLink'>Terms & Conditions</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-md-3 col-lg-3 fotterclmdWIdthStyle2">
            <p className="footerLink linkForFooter">Address:</p>
            <p className="footerLink">Shota Rustavili Street 53B </p>
            <p className="footerLink">Tashkent, Uzbekistan</p>
            <p className="footerLink">+99895 195-00-55</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mlFotterMobileText">
          <img className="footerLogoStyleMobile" src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/footerLogo.svg?alt=media&token=c7017761-8945-434c-9a5d-508efe8debe2" alt="Img" /> 
          <div className="col-sm-6 col-md-6 col-lg-6 fullHDDisableFooter">
            <p className="footerLinkMobile linkForFooterMobile contain">Address:</p>
            <p className="footerLinkMobile">Shota Rustavili Street 53B </p>
            <p className="footerLinkMobile">Tashkent, Uzbekistan</p>
            <p className="footerLinkMobile">+99895 195-00-55</p>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 fullHDDisableFooter">
            <Link to='/About' className='footerLinkMobile'>About us</Link> <br className='activeBrInMobile' />
            <Link to='/Services' className='footerLinkMobile'>Our Services</Link>
            <p className='footerLinkMobile'>Privacy Policy</p>
            <p className='footerLinkMobile'>Terms & Conditions</p>
          </div>
        </div>
        <div className="mobileSocialeMediaBloc">
          <a href="https://www.facebook.com/watch/mahoratLC/">
            <img className='footerSocialMediaMobile socialMediaMobile' src={Facebook} alt="Img" />
          </a>
          <a href="https://www.instagram.com/mahoratmanagement/">
            <img className='footerSocialMediaMobile' src={Instagram} alt="Img" />
          </a>
          <a href="https://t.me/mahorat_management">
            <img className='footerSocialMediaMobile' src={Telegram} alt="IMg" />
          </a>
        </div>
      </div>
      <hr className='footerHr' />
      <p className="footerLatestText">
        © 2022 Mahorat & Management. All rights reserved
      </p>
    </div>
  )
}
