import axios from "axios"

const baseURL = "https://admin.mahoratsoft.uz/index"

const service = axios.create({
    baseURL
});

service.interceptors.response.use(
    (res) => res.data,
    (error) => {
        if (error.response.status === 401) {
            window.location.replace("/logout");
            localStorage.removeItem("token");
        }
        return Promise.reject(error);
    }
);

export {service}