
import React from "react";
import "./Services.css";

const Services = () => {
    return (
        <div className="ServicesWrapper">
            <div className="container">
                <p className="servicesTitle">OUR SERVICES</p>
            </div>
            <div className="container serviceTopStyle">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 gridBorderAll1">
                        <div className="flexContainerSerive">
                            <div>
                                <img className="imgLefetStyleService" src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/research%201.svg?alt=media&token=1f704803-4a4a-49da-aa71-06f0dcd3b556" alt="Img" />
                            </div>
                            <div className="servicesTxts">
                                <p className="serviceBigText">Research</p>
                                <p className="serviceSmallText">
                                    Conducting social surveys in partnership <br/> 
                                    with local and international research <br />
                                    institutions on HRD, research and analysis <br />
                                    in the field of education and labor market. 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 gridBorderAll2">
                        <div className="flexContainerSerive">
                            <div>
                                <img className="imgLefetStyleService1" src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/learning%201.svg?alt=media&token=1040342e-d54f-421e-8120-e84e534978ba" alt="Img" />
                            </div>
                            <div className="servicesTxts">
                                <p className="serviceBigText">Adult Learning</p>
                                <p className="serviceSmallText">
                                    Organization and conduct of trainings for adults, including continuing professional development programs, advanced training, retraining of teachers and managers of educational institutions.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 gridBorderAll3">
                        <div className="flexContainerSerive">
                            <div>
                                <img className="imgLefetStyleService" src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/translating%201.svg?alt=media&token=608fc978-58f1-458f-bafb-f30db134b14a" alt="Img" />
                            </div>
                            <div className="servicesTxts">
                                <p className="serviceBigText">Translation services</p>
                                <p className="serviceSmallText">
                                    Written and oral translations from <br />
                                    English into Russian, Uzbek, Tajik, <br />
                                    Kazakh, Turkmen languages and vice <br />
                                    versa.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 gridBorderAll2">
                        <div className="flexContainerSerive">
                            <div>
                                <img className="imgLefetStyleService1" src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/organizer%201.svg?alt=media&token=147907bd-1e1d-41c3-acf3-15d628625bce" alt="Img" />
                            </div>
                            <div className="servicesTxts">
                                <p className="serviceBigText">Event Management</p>
                                <p className="serviceSmallText">
                                    Organizing round tables, conferences, webinars, trainings, workshops with the officials, professional associations, and non-governmental organizations.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 gridBorderAll4">
                        <div className="flexContainerSerive">
                            <div>
                                <img className="imgLefetStyleService" src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/Group.svg?alt=media&token=37083273-113e-4315-b59f-f74df7f8ba81" alt="Img" />
                            </div>
                            <div className="servicesTxts">
                                <p className="serviceBigText">Logistics</p>
                                <p className="serviceSmallText">
                                    Open to provide logistic services for the <br />
                                    international and local partners. Provision <br />
                                    of logistics services, including transfers, <br />
                                    technical support in organizing trainings, <br />
                                    coffee breaks, seminars and other events.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="flexContainerSerive">
                            <div>
                                <img className="imgLefetStyleService1" src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/teacher%201.svg?alt=media&token=5bcb504b-264b-4c8a-97f6-d6e4e6bc6c04" alt="Img" />
                            </div>
                            <div className="servicesTxts">
                                <p className="serviceBigText">Outsourcing</p>
                                <div className="serviceSmallText">
                                    Offering following outsourcing services:
                                    <ul>
                                        <li>
                                            Finance management (tax reporting; accounting)
                                        </li>
                                        <li>
                                            IT Support
                                        </li>
                                        <li>
                                            Data analysis
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;