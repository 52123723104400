import React from 'react';
import CardCarousel from '../CardCarousel/CardCarousel';
import { useState } from 'react';

import './Home.css';
import styled from 'styled-components';


const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <div className="text ourProjectSmallText">
      {isReadMore ? text.slice(0, 470) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...read more" : " show less"}
      </span>
    </div>
  );
};



export default function Home() {

  return (
    <div className='home-container'>
    <div className='homeWrapper'>
      <div className="container">
        <p className='homeBasicTextStyle'>
              Human capital is the <br />
              foundation of societal <br />
              development
        </p>
        </div>
      </div>

      <div className="homeBottomText container">
        <p className='homeBottomBigText'>
          We strive to advance human capital
        </p>

        <p className='homeBottomSmallText'>
          The name ‘Mahorat’ means Master. The organization's building the international Masters - Experts in reaching the organization’s aim HRD-Human Resource Development by research-based evidence. We embrace the changes toward innovations and constructive dialogue in HRD.
        </p>
      </div>
      <div className="sectionWrapper">
        <img className="sectionImgStyle" src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/sectionImg.svg?alt=media&token=284738da-a854-4b59-aec9-d92df007118f" alt="Img" />
      </div>
      <div className="ourProjectWrapper container">
        <p className="ourPorojectBigText">
          OUR PROJECTS
        </p>
        <div>
          <div className="row">
            <div className="col-sm-12 col-md-5 col-lg-5">
              <img className="oirProjextImgStyle" src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/ourProjectImg.svg?alt=media&token=7f85cf36-b2eb-4533-800a-887704c233a8" alt="Img" />
            </div>
            <div className="col-sm-12 col-md-7 col-lg-7">
              <p className='ourProjectBigText'>
                ETF ‘Torino Process’ project
              </p>

              <p className="ourProjectSmallText">
                Mahorat & Management has been cooperating with the <br />
                European Education Foundation since May 2019 within the <br />
                framework of the Torino Process project. It is known that <br />
                Uzbekistan has been participating in the Torino Process since <br />
                2010 at the initiative of the European Training Foundation. <br />
                The Torino process monitors political progress every two <br />
                years, allowing partner countries to coordinate and assess <br />
                their progress in vocational education and training reforms.
              </p>
            </div>
          </div>
        </div>
        <hr className='hrStyleHOme' />
        <div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <p className='ourProjectBigText'>
                Journal Research in Adult Learning <br /> and Education (RALED)
              </p>

              <ReadMore className="ourProjectSmallText">
                The Adult Education and Research (RALED) journal 
                encompasses lifelong learning, quality assurance and adult 
                learning, in accordance with labor market requirements. This 
                includes forms of education and upbringing aimed at ensuring 
                the participation of adults in society. This journal of research 
                and education is published by practitioners and researchers in 
                Uzbekistan and can be a resource for adult learning and education management with the best practice in the world.
                
                 RALED aims to provide a medium for informed discussion about the principles and practice which determine the methods of social capital in society. It also provides a forum for the exchange of information and ideas among practitioners and researchers worldwide.
              </ReadMore>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <img className="ourProjextImgStyle1" src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/ourProjectImg1.svg?alt=media&token=66c9689f-0519-4f01-8947-6741ba082b5d" alt="Img" />
            </div>
          </div>
        </div>
        <hr className='hrStyleHOme' />
        <div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <img className="ourProjextImgStyle" src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/ourProjectImg2.svg?alt=media&token=6b2c2665-ca40-4646-9e43-5c0661bed278" alt="Img" />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <p className='ourProjectBigText'>
                Creating a regulatory framework of <br/> dual training system
              </p>

              <p className="ourProjectSmallText">
                The project ‘Creating an educational and regulatory framework and methodological support for the introduction of a dual training system in the system of vocational education’ financed by grant funds from the government, which is implemented in partnership with Uzbekistan Teachers of English Association (UzTEA), Mahorat&Management and the Ministry of Higher and Secondary Specialized Education is an ongoing project. 
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="ourPartners">
        <div className="container">
          <CardCarousel />
        </div>
      </div>
    </div>
  )
}
