import React from 'react'
import RaledImg from '../Assets/Raled.svg'
import './Project.css'

export default function Project() {
  return (
    <div className="projectWrapper">
      <div className="container">
        <p className="projectsTitleStyle">OUR PROJECTS</p>
      </div>
      <div className="projectbgImgStyle">
        <div className="container">
          <p className="projectBgImgTextStyle1">
            Mahorat & Management actively cooperates with governmental and{' '}
            <br />
            non-governmental institutions and implements joint projects with{' '}
            <br />
            the involvement of international experts. And also, we are working{' '}
            <br />
            with youth helping them to gain skills and find their careers.
          </p>
          <p className="projectBgImgTextStylr">
            We have started offering national and international experts’
            services <br />
            for local companies and organizations that needs consultancy and{' '}
            <br />
            strategic planning.
          </p>
          <p className="projectBgImgTextStylr">
            Currently, our projects are related to education management, <br />
            continuous professional development & consultancy.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="accordionHederStyleInProject">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  <img src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/Group%20(1).svg?alt=media&token=a91bdd01-7fc3-4647-aae0-50d7954efe5d" alt="IMG" />{' '}
                  <span className="projectAccordingTextStyle">
                    Retraining and advanced training courses for adults
                  </span>
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <img
                        className="projectAccordingInsideImgStyle"
                        src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/ourProjectImg2.svg?alt=media&token=6b2c2665-ca40-4646-9e43-5c0661bed278"
                        alt="Img"
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <p className="projectAccordingInsideTextStyle prejectMtStyle">
                        Today, the number of participants in the program exceeds
                        600 people, for whom online trainings are regularly
                        organized with the participation of qualified teachers
                        and psychologists. To improve the professional
                        competency of managers and teachers in the field of
                        education (teachers of all categories, methodologists,
                        assessors, trainers, researchers and specialists) in
                        accordance with the levels of International Standard
                        Classification of Education since 2018 Mahorat &
                        Management implementing a Program “Continuing
                        Professional Development Program for Educators” that
                        serves as a "road map" for managers and teachers to
                        improve their professional skills from primary level to
                        international expert level.
                      </p>
                    </div>
                  </div>
                  <div className="projectAccordingInsideTextStyle">
                    The CPD "Road map" includes: Based on this Program managers
                    / teachers improve their skills on the basis of the
                    principle of lifelong learning at 4 levels:
                    <ul>
                      <li>Foundation level</li>
                      <li>Developing level</li>
                      <li>Expert level </li>
                      <li>National Expert at International Level</li>
                    </ul>
                    Each level consists of 5 modules which equals a total of 180
                    hours of trainings. The total volume of practical and
                    theoretical training on 4 qualification levels is 720 hours.
                    An individual (pedagogues, managers) who successfully passes
                    each level is awarded a Certificate of Achievement, which
                    indicates their level of qualification in their working
                    sphere.
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <img src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/Group%20(2).svg?alt=media&token=e405fa56-48a4-4ed4-932b-1643cba2f7f3" alt="IMG" />{' '}
                  <span className="projectAccordingTextStyle">
                    Journal Research in Adult Learning and Education (RALED)
                  </span>
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <img
                        className="projectAccordingInsideImgStyle1"
                        src={RaledImg}
                        alt="Img"
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <p className="projectAccordingInsideTextStyle prejectMtStyle">
                        One of the latest projects of Mahorat & Management is
                        the journal ‘Research in Adult Learning and Education’.
                        The Research in Adult Learning and Education (RALED)
                        journal is a publication for Uzbek and world
                        practitioners and researchers involved in issues of
                        life-long learning, quality assurance in education, and
                        adult learning, taking into account the requirements of
                        the labour market. This includes all forms of education
                        and training aimed at ensuring adult participation in
                        society and work: formal, non-formal and informal.
                      </p>
                    </div>
                  </div>
                  <div className="projectAccordingInsideTextStyle">
                    It covers, but is not limited to, these related areas:
                    <ul>
                      <li>
                        pedagogy and psychology of vocational and professional
                        training
                      </li>
                      <li>social partnership development</li>
                      <li>
                        advanced training and retraining of teaching and
                        management staff
                      </li>
                      <li>adult education</li>
                      <li>workplace training</li>
                      <li>professional development</li>
                    </ul>
                  </div>
                  <p className="projectAccordingInsideTextStyle">
                    RALED aims to provide a medium for informed discussion about
                    the principles and practice which determine the ways in
                    which all aspects of adult education and learning are taught
                    and delivered around the world. It also provides a forum for
                    the exchange of information and ideas among practitioners
                    and researchers worldwide.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <img src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/rating%201.svg?alt=media&token=ce84fea5-8af4-43a3-9435-d53d10adadd3" alt="IMG" />{' '}
                  <span className="projectAccordingTextStyle">
                    Senior Experts Service (SES)
                  </span>
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <p className="projectAccordingInsideTextStyle prejectMtStyleNoneImg">
                    Since June 2019, Mahorat & Management actively cooperates
                    with an expert organization in Germany, which brings
                    together more than 12,500 retired professionals and
                    executives providing advisory services on a voluntary basis.
                    Within the framework of the agreement signed between the
                    Mahorat & Management and SES, with the participation of the
                    SES expert on information technologies Sh.Behjad, work has
                    begun on the implementation of an information system for
                    education management in the Academic Lyceum of UzSWLU.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <img src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/innovation%201.svg?alt=media&token=6505c754-3470-46a2-8d15-e0fd5878e2a7" alt="IMG" />{' '}
                  <span className="projectAccordingTextStyle">
                    VET Reforms Project (SVRP) in Uzbekistan
                  </span>
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <p className="projectAccordingInsideTextStyle prejectMtStyleNoneImg">
                    Assistance to ‘Support to VET Reforms Project (SVRP) in
                    Uzbekistan’ for conducting Governance Survey. Mahorat &
                    Management is supporting the SVRP to conduct during
                    April-July, 2022 a survey into the quality of VET governance
                    in Uzbekistan. The survey aims to evaluate VET governance
                    according to key functions of the VET system. The results
                    from this study can be used to recommend changes to specific
                    dimensions of governance. The survey will be digitally
                    managed by SVRP partner CEMETS in Switzerland, but it needs
                    preparation and follow-up on the ground in Uzbekistan.{' '}
                    <br />
                    It is expected that the total number of survey participants
                    exceeds 2,500 from the system of ministries of higher and
                    secondary specialized education, employment and labor
                    relations, the chamber of commerce and industry and other
                    VET stakeholders.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  <img src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/research-and-development%201.svg?alt=media&token=c7d6fc20-cde1-42ed-a21e-eeb434870423" alt="IMG" />{' '}
                  <span className="projectAccordingTextStyle">
                    ETF ‘ Civil Society Organizations and Human Capital
                    Development’
                  </span>
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <img className='projectCivilSocietyStyle' src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/ProjectETFImg.svg?alt=media&token=73a412b0-30fc-4f2d-ab00-4ef92e191099" alt="Img" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <p className="projectAccordingInsideTextStyle prejectMtStyle">
                        Conducting the “Survey on contribution of civil society
                        organizations to the lifelong development of human
                        capital” by request of the European Training Foundation
                        (ETF) By request of the European Training Foundation,
                        during August – December 2020 ‘Mahorat & Management’
                        conducted a survey among civil society organizations
                        (SCOs) in Uzbekistan. The aim of this study is learning
                        to what extent SCOs contribute to the formation of
                        skills, competencies, knowledge and attitudes that
                        contribute to the employment of people and the
                        realization of their potential i.e. human capital
                        development (HCD).
                      </p>
                    </div>
                  </div>
                  <p className="projectAccordingInsideTextStyle">
                    We provided a questionnaire that contained four sections:
                    organization profile, CSOs response to the COVID-19 pandemic
                    effects, stakeholders/policy dialogue and potential of their
                    organization in the HCD sector. This survey is conducted
                    among selected 25 CSOs across the Republic of Uzbekistan
                    which highly promoted informal and non-formal learning
                    during a pandemic period for youth, women, and employment.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSix">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  <img src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/agile%201.svg?alt=media&token=9ac72fb0-2cec-4814-9c05-073ad05a549d" alt="IMG" />{' '}
                  <span className="projectAccordingTextStyle">
                    ETF ‘Torino Process’ project - 5th round (2018-2020)
                  </span>
                </button>
              </h2>
              <div
                id="collapseSix"
                className="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <img
                        className="projectDaryoImgStyleTorino projectCivilSocietyStyle"
                        src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/ourProjectImg.svg?alt=media&token=7f85cf36-b2eb-4533-800a-887704c233a8"
                        alt="Img"
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <p className="projectAccordingInsideTextStyle prejectMtStyle">
                        Mahorat & Management has been cooperating with the
                        European Education Foundation since May 2019 within the
                        framework of the Turin Process project, round 5. The
                        Torino process monitors political progress every two
                        years, allowing partner countries to coordinate and
                        assess their progress in vocational education and
                        training reforms. In the fifth phase of the project from
                        2019 to 2020, Mahorat & Management together with the
                        Ministry of Higher and Secondary Special Education,
                        other VET stakeholders actively supported the
                        implementation of the Torino process, including the
                        establishment of focus groups, conducting launch event,
                        discussing the policy analysis.
                      </p>
                    </div>
                  </div>
                  <p className="projectAccordingInsideTextStyle">
                    During the implementation of this project the preparatory
                    meetings in Tashkent, Andijan and Syrdarya regions were
                    held, as well as meetings at the Ministry of Higher and
                    Secondary Specialized Education with the participation of a
                    group of experts from the Torino Process, in other
                    interested ministries were held. The launch event was held
                    on June 14, 2019 at the Radisson Blue Hotel. Experts have
                    prepared a draft National Report and is in the process of
                    discussion with practitioners, representatives of
                    educational institutions, professional associations,
                    government and non-governmental organizations throughout our
                    republic.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSeven">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  <img src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/brainstorming%201.svg?alt=media&token=39a06664-95c1-40fd-9333-bfb4368537b6" alt="IMG" />{' '}
                  <span className="projectAccordingTextStyle">
                    ETF ‘Mission and consultations including DARYA Action’
                  </span>
                </button>
              </h2>
              <div
                id="collapseSeven"
                className="accordion-collapse collapse"
                aria-labelledby="headingSeven"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <img
                        className="projectDaryoImgStyle"
                        src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/ProjectDarya.svg?alt=media&token=bb1f9869-9251-418d-b0fa-1f749562e6a4"
                        alt="Img"
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <p className="projectAccordingInsideTextStyle prejectMtStyle">
                        Assistance to organizing ETF Mission and consultations
                        including DARYA Action. As a long-term partner for the
                        ETF “Mahorat & Management” team has assisted to organise
                        an ETF Team Mission to Tashkent in order to present the
                        activities in Uzbekistan in the ETF 2021-2027 work
                        programme and in particular cooperation in the
                        forthcoming EU Regional Action DARYA, which ETF is
                        planning to implement on behalf of the European Union.
                      </p>
                    </div>
                  </div>
                  <p className="projectAccordingInsideTextStyle">
                    The ETF Mission was organized within the Co-operation in
                    Vocational Education and Training, Labour Market and Skills
                    Development, Lifelong Learning between the European Training
                    Foundation and Government Institutions of Uzbekistan. Our
                    support consisted in planning and organizing meetings, round
                    tables, as well as logistical support in organizing the
                    visit, translation services and meetings of ETF team
                    comprising of senior specialist ETF Darya team members led
                    by the ETF Country Liaison for Uzbekistan – Mr.Ian Cumming,
                    and Focal point for Central Asia Ms. Christine Hemschemeier
                    (ETF) during their visit to Tashkent from 27 June to 6 July
                    2022.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingEight">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  <img src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/discussion%201.svg?alt=media&token=2156dc67-a5d4-4759-a4b5-8b766b696a01" alt="IMG" />{' '}
                  <span className="projectAccordingTextStyle">
                    Conducting events (conferences/workshops/webinars)
                  </span>
                </button>
              </h2>
              <div
                id="collapseEight"
                className="accordion-collapse collapse"
                aria-labelledby="headingEight"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <img
                        className="projectUzTeaImgStyle"
                        src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/ProjectUzTea.svg?alt=media&token=0489920e-e94a-42ff-bec2-6da31c8b815d"
                        alt="Img"
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <p className="projectAccordingInsideTextStyle prejectMtStyle projectLatestOpenCleseSectionStyleText">
                        <span>
                          <img
                            className="projectDotsImgStyle"
                            src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/ProjectDots.svg?alt=media&token=eed2fa5a-e43b-405c-9c1f-bbda6d5fde8a"
                            alt="Img"
                          />
                        </span>
                        <span>
                          By request of Uzbekistan Teachers of English
                          Association which has implemented the US funded
                          Project “Core Values Standard of the Teacher Training
                          based on LMS Technology for pre-school education
                          Teachers” during December, 2021 – March, 2022 “Mahorat
                          & Management” assisted in holding an online conference
                          and 8 online webinars.
                        </span>
                      </p>
                    </div>
                  </div>
                  <p className="projectAccordingInsideTextStyle">
                    The project team prepared 8 curriculum modules based on Core
                    Professional Standard for Teachers for Uzbekistan. The TOT
                    group, for which the delivery of the curriculum modules was
                    planned, were identified among the teachers of preschool
                    education. The developed modules are gradually delivered to
                    TOT groups starting from January 21, 2022 until February 28,
                    2022 using Zoom platform. The average number of participants
                    in each of these online webinars is 80-85 people.
                  </p>
                  <p className="projectAccordingInsideTextStyle">
                    On March 4, 2022 an international conference of participants
                    was conducted. The conference was held in English with
                    simultaneous translation into Uzbek and Russian. The
                    conference was attended by teachers and university students.
                    Students enrolled in the Master of Arts in Education and
                    Innovation (MAEDIN) at Webster University also attended the
                    conference. As guests, the conference was attended by
                    representatives of the Ministry of Preschool Education, the
                    European Training Foundation and other non-governmental
                    organizations.
                  </p>
                  <div className="insideDotsTextLeftStyle">
                    <p className="projectAccordingInsideTextStyle projectLatestOpenCleseSectionStyleText">
                      <span>
                        <img
                          className="projectDotsImgStyle2"
                          src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/ProjectDots.svg?alt=media&token=eed2fa5a-e43b-405c-9c1f-bbda6d5fde8a"
                          alt="Img"
                        />
                      </span>
                      Mahorat & Management team is actively involved in
                      organizing TOT, webinars and workshops within the Project
                      ‘Creating an educational and regulatory framework and
                      methodological support for the introduction of a dual
                      training system in the system of vocational education’.
                    </p>
                  </div>
                  <p className="projectAccordingInsideTextStyle">
                    This project is being implemented by Uzbekistan Teachers of
                    English Association (UzTEA) in cooperation the Ministry of
                    Higher and Secondary Specialized Education starting from
                    September, 2021 that is financed by the Public Fund for
                    Support of Non-Governmental Organizations and Other
                    Institutions of Civil Society under the Oliy Majlis
                    (Parliament) of the Republic of Uzbekistan. <br /> The
                    project provides for the development of 10 professional
                    standards in priority areas, the development of a
                    methodological manual for the development of dual education
                    programs, curricula (modules) for 10 selected professions
                    for the introduction of dual education, a procedure for
                    assessing the qualifications of graduates of the dual
                    education program, as well as organization of 10 seminars
                    and short-term trainings on the introduction of dual
                    education for more than 3,000 managers and teachers working
                    in the vocational education system.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
