import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home/Home";
import Footer from "./Components/Footer/Footer";
import About from "./Components/About/About";
import Services from "./Components/Services/Services";
import Contact from "./Components/Contact/Contact";
import Apply from "./Components/Apply/Apply";
import Experts from "./Components/Experts/Experts";
import Project from "./Components/Project/Project";
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'



function App() {
  return (
    <div>
      <BrowserRouter>
          < ToastContainer />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route Exact path="About" element={<About />} />
          <Route Exact path="Services" element={<Services />} />
          <Route Exact path="Apply" element={<Apply />} />
          <Route Exact path="Experts" element={<Experts />} />
          <Route Exact path="Project" element={<Project />} />

          <Route Exact path="Contact" element={<Contact />} />
        </Routes>
        <Footer />
        </BrowserRouter>
    </div>
  );
}

export default App;
