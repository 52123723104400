import {
    configureStore
} from '@reduxjs/toolkit'
import applyReducer from './reducers/applyReducer'



export const store = configureStore({
    reducer: {
        apply: applyReducer,
    },
})