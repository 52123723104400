const CITIZENSHIP_CHOICES = [
    {value:'afghan', name:'Afghan'},

    {value:'albanian', name:'Albanian'},

    {value:'algerian', name:'Algerian'},

    {value:'american', name:'American'},

    {value:'andorran', name:'Andorran'},

    {value:'angolan', name:'Angolan'},

    {value:'antiguans', name:'Antiguans'},

    {value:'argentinean', name:'Argentinean'},

    {value:'armenian', name:'Armenian'},

    {value:'australian', name:'Australian'},

    {value:'austrian', name:'Austrian'},

    {value:'azerbaijani', name:'Azerbaijani'},

    {value:'bahamian', name:'Bahamian'},

    {value:'bahraini', name:'Bahraini'},

    {value:'bangladeshi', name:'Bangladeshi'},

    {value:'barbadian', name:'Barbadian'},

    {value:'barbudans', name:'Barbudans'},

    {value:'batswana', name:'Batswana'},

    {value:'belarusian', name:'Belarusian'},

    {value:'belgian', name:'Belgian'},

    {value:'belizean', name:'Belizean'},

    {value:'beninese', name:'Beninese'},

    {value:'bhutanese', name:'Bhutanese'},

    {value:'bolivian', name:'Bolivian'},

    {value:'bosnian', name:'Bosnian'},

    {value:'brazilian', name:'Brazilian'},

    {value:'british', name:'British'},

    {value:'bruneian', name:'Bruneian'},

    {value:'bulgarian', name:'Bulgarian'},

    {value:'burkinabe', name:'Burkinabe'},

    {value:'burmese', name:'Burmese'},

    {value:'burundian', name:'Burundian'},

    {value:'cambodian', name:'Cambodian'},

    {value:'cameroonian', name:'Cameroonian'},

    {value:'canadian', name:'Canadian'},

    { value: 'cape verdean', name: 'Cae Verdean' },

    { value: 'central african', name: 'Central African'},

    {value:'chadian', name:'Chadian'},

    {value:'chilean', name:'Chilean'},

    {value:'chinese', name:'Chinese'},

    {value:'colombian', name:'Colombian'},

    {value:'comoran', name:'Comoran'},

    {value:'congolese', name:'Congolese'},

    { value: 'costa rican', name: 'Costa Rican'},

    {value:'croatian', name:'Croatian'},

    {value:'cuban', name:'Cuban'},

    {value:'cypriot', name:'Cypriot'},

    {value:'czech', name:'Czech'},

    {value:'danish', name:'Danish'},

    {value:'djibouti', name:'Djibouti'},

    {value:'dominican', name:'Dominican'},

    {value:'dutch', name:'Dutch'},

    { value: 'east timorese', name: 'East Timorese'},

    {value:'ecuadorean', name:'Ecuadorean'},

    {value:'egyptian', name:'Egyptian'},

    {value:'emirian', name:'Emirian'},

    { value: 'equatorial guinean', name: 'Equatorial Guinean'},

    {value:'eritrean', name:'Eritrean'},

    {value:'estonian', name:'Estonian'},

    {value:'ethiopian', name:'Ethiopian'},

    {value:'fijian', name:'Fijian'},

    {value:'filipino', name:'Filipino'},

    {value:'finnish', name:'Finnish'},

    {value:'french', name:'French'},

    {value:'gabonese', name:'Gabonese'},

    {value:'gambian', name:'Gambian'},

    {value:'georgian', name:'Georgian'},

    {value:'german', name:'German'},

    {value:'ghanaian', name:'Ghanaian'},

    {value:'greek', name:'Greek'},

    {value:'grenadian', name:'Grenadian'},

    {value:'guatemalan', name:'Guatemalan'},

    { value: 'guinea-bissauan', name: 'Guinea-Bissauan'},

    {value:'guinean', name:'Guinean'},

    {value:'guyanese', name:'Guyanese'},

    {value:'haitian', name:'Haitian'},

    {value:'herzegovinian', name:'Herzegovinian'},

    {value:'honduran', name:'Honduran'},

    {value:'hungarian', name:'Hungarian'},

    {value:'i-kiribati', name:'I-Kiribati'},

    {value:'icelander', name:'Icelander'},

    {value:'indian', name:'Indian'},

    {value:'indonesian', name:'Indonesian'},

    {value:'iranian', name:'Iranian'},

    {value:'iraqi', name:'Iraqi'},

    {value:'irish', name:'Irish'},

    {value:'israeli', name:'Israeli'},

    {value:'italian', name:'Italian'},

    {value:'ivorian', name:'Ivorian'},

    {value:'jamaican', name:'Jamaican'},

    {value:'japanese', name:'Japanese'},

    {value:'jordanian', name:'Jordanian'},

    {value:'kazakhstani', name:'Kazakhstani'},

    {value:'kenyan', name:'Kenyan'},

    { value: 'kittian and nevisian', name:'Kittian and Nevisian'},

    {value:'kuwaiti', name:'Kuwaiti'},

    {value:'kyrgyz', name:'Kyrgyz'},

    {value:'laotian', name:'Laotian'},

    {value:'latvian', name:'Latvian'},

    {value:'lebanese', name:'Lebanese'},

    {value:'liberian', name:'Liberian'},

    {value:'libyan', name:'Libyan'},

    {value:'liechtensteiner', name:'Liechtensteiner'},

    {value:'lithuanian', name:'Lithuanian'},

    {value:'luxembourger', name:'Luxembourger'},

    {value:'macedonian', name:'Macedonian'},

    {value:'malagasy', name:'Malagasy'},

    {value:'malawian', name:'Malawian'},

    {value:'malaysian', name:'Malaysian'},

    {value:'maldivan', name:'Maldivan'},

    {value:'malian', name:'Malian'},

    {value:'maltese', name:'Maltese'},

    {value:'marshallese', name:'Marshallese'},

    {value:'mauritanian', name:'Mauritanian'},

    {value:'mauritian', name:'Mauritian'},

    {value:'mexican', name:'Mexican'},

    {value:'micronesian', name:'Micronesian'},

    {value:'moldovan', name:'Moldovan'},

    {value:'monacan', name:'Monacan'},

    {value:'mongolian', name:'Mongolian'},

    {value:'moroccan', name:'Moroccan'},

    {value:'mosotho', name:'Mosotho'},

    {value:'motswana', name:'Motswana'},

    {value:'mozambican', name:'Mozambican'},

    {value:'namibian', name:'Namibian'},

    {value:'nauruan', name:'Nauruan'},

    {value:'nepalese', name:'Nepalese'},

    { value: 'new zealander', name:'New Zealander'},

    {value:'nicaraguan', name:'Nicaraguan'},

    {value:'nigerian', name:'Nigerian'},

    {value:'nigerien', name:'Nigerien'},

    { value: 'north korean', name:'North Korean'},

    { value: 'northern irish', name:'Northern Irish'},

    {value:'norwegian', name:'Norwegian'},

    {value:'omani', name:'Omani'},

    {value:'pakistani', name:'Pakistani'},

    {value:'palauan', name:'Palauan'},

    {value:'panamanian', name:'Panamanian'},

    { value: 'papua new guinean', name:'Papua New Guinean'},

    {value:'paraguayan', name:'Paraguayan'},

    {value:'peruvian', name:'Peruvian'},

    {value:'polish', name:'Polish'},

    {value:'portuguese', name:'Portuguese'},

    {value:'qatari', name:'Qatari'},

    {value:'romanian', name:'Romanian'},

    {value:'russian', name:'Russian'},

    {value:'rwandan', name:'Rwandan'},

    { value: 'saint lucian', name:'Saint Lucian'},

    {value:'salvadoran', name:'Salvadoran'},

    {value:'samoan', name:'Samoan'},

    { value: 'san marinese', name:'San Marinese'},

    { value: 'sao tomean', name:'Sao Tomean'},

    {value:'saudi', name:'Saudi'},

    {value:'scottish', name:'Scottish'},

    {value:'senegalese', name:'Senegalese'},

    {value:'serbian', name:'Serbian'},

    {value:'seychellois', name:'Seychellois'},

    { value: 'sierra leonean', name:'Sierra Leonean'},

    {value:'singaporean', name:'Singaporean'},

    {value:'slovakian', name:'Slovakian'},

    {value:'slovenian', name:'Slovenian'},

    { value: 'solomon islander', name:'Solomon Islander'},

    {value:'somali', name:'Somali'},

    { value: 'south african', name:'South African'},

    { value: 'south korean', name:'South Korean'},

    {value:'spanish', name:'Spanish'},

    { value: 'sri lankan', name:'Sri Lankan'},

    {value:'sudanese', name:'Sudanese'},

    {value:'surinamer', name:'Surinamer'},

    {value:'swazi', name:'Swazi'},

    {value:'swedish', name:'Swedish'},

    {value:'swiss', name:'Swiss'},

    {value:'syrian', name:'Syrian'},

    {value:'taiwanese', name:'Taiwanese'},

    {value:'tajik', name:'Tajik'},

    {value:'tanzanian', name:'Tanzanian'},

    {value:'thai', name:'Thai'},

    {value:'togolese', name:'Togolese'},

    {value:'tongan', name:'Tongan'},

    { value: 'trinidadian or tobagonian', name:'Trinidadian or Tobagonian'},

    {value:'tunisian', name:'Tunisian'},

    {value:'turkish', name:'Turkish'},

    {value:'tuvaluan', name:'Tuvaluan'},

    {value:'ugandan', name:'Ugandan'},

    {value:'ukrainian', name:'Ukrainian'},

    {value:'uruguayan', name:'Uruguayan'},

    {value:'uzbekistani', name:'Uzbekistani'},

    {value:'venezuelan', name:'Venezuelan'},

    {value:'vietnamese', name:'Vietnamese'},

    {value:'welsh', name:'Welsh'},

    {value:'yemenite', name:'Yemenite'},

    {value:'zambian', name:'Zambian'},

    {value:'zimbabwean', name:'Zimbabwean'},
]

export default CITIZENSHIP_CHOICES;