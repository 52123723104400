import { useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import styled from 'styled-components'

import './Navbar.css'

function CollapsibleExample() {

    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
    window.onscroll = function() {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  }, []);

  const Button = styled.button`
    /* Same as above */
  `
  const ButtonToggle = styled(Button)`
    color:  #363DF1;
    ${({ active }) =>
      active &&
      `
      margin-bottom: none;
      border-bottom: none;
      opacity: none;
  `}
  `

  const [active, setActive] = useState(false)
  const [active1, setActive1] = useState(false)
  const [active2, setActive2] = useState(false)
  const [active3, setActive3] = useState(false)
  const [active4, setActive4] = useState(false)
  const [active5, setActive5] = useState(false)
  const [active6, setActive6] = useState(false)
  const [active7, setActive7] = useState(false)

  const styleClick = () => {
    setActive(true)
    setActive1(false)
    setActive2(false)
    setActive3(false)
    setActive4(false)
    setActive5(false)
    setActive6(false)
    setActive7(false)
  }
  const styleClick1 = () => {
    setActive(false)
    setActive1(true)
    setActive2(false)
    setActive3(false)
    setActive4(false)
    setActive5(false)
    setActive6(false)
    setActive7(false)
  }
  const styleClick2 = () => {
    setActive(false)
    setActive1(false)
    setActive2(true)
    setActive3(false)
    setActive4(false)
    setActive5(false)
    setActive6(false)
    setActive7(false)
  }
  const styleClick3 = () => {
    setActive(false)
    setActive1(false)
    setActive2(false)
    setActive3(true)
    setActive4(false)
    setActive5(false)
    setActive6(false)
    setActive7(false)
  }
  const styleClick4 = () => {
    setActive(false)
    setActive1(false)
    setActive2(false)
    setActive3(false)
    setActive4(true)
    setActive5(false)
    setActive6(false)
    setActive7(false)
  }
  const styleClick5 = () => {
    setActive(false)
    setActive1(false)
    setActive2(false)
    setActive3(false)
    setActive4(false)
    setActive5(true)
    setActive6(false)
    setActive7(false)
  }
  const styleClick6 = () => {
    setActive(false)
    setActive1(false)
    setActive2(false)
    setActive3(false)
    setActive4(false)
    setActive5(false)
    setActive6(false)
  }

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={scrolled ? "navbarStyleBasic floatingNav" : "navbarStyleBasic"}
      variant="dark"
    >
      <Container>
        <Link to="/">
          <img
              onClick={() => styleClick6()}
              className={
                active6
                  ? 'navbarLogoStye withBorder'
                  : 'navbarLogoStye noBorder'
              }
            src="https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/Logo.svg?alt=media&token=27823e41-5209-4cc9-8f24-f8b4cf2cbb73"
            alt="Logo"
          />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <Link
              onClick={() => styleClick()}
              className={
                active
                  ? 'navbarPageTextStyle withBorder'
                  : 'navbarPageTextStyle noBorder'
              }
              to="/About"
            >
              About
            </Link>
            <Link
              onClick={() => styleClick5()}
              className={
                active5
                  ? 'navbarPageTextStyle withBorder'
                  : 'navbarPageTextStyle noBorder'
              }
              to="/Services">
              Services
            </Link>
            <Link
              onClick={() => styleClick4()}
              className={
                active4
                  ? 'navbarPageTextStyle withBorder'
                  : 'navbarPageTextStyle noBorder'
              }
              to="/Project">
              Projects
            </Link>
            <Link
              onClick={() => styleClick3()}
              className={
                active3
                  ? 'navbarPageTextStyle withBorder'
                  : 'navbarPageTextStyle noBorder'
              }
              to="/Experts">
              Experts
            </Link>
            <Link
              onClick={() => styleClick2()}
              className={
                active2
                  ? 'navbarPageTextStyle withBorder'
                  : 'navbarPageTextStyle noBorder'
              }
              to="/Apply">
              Apply
            </Link>
            <a
              className="navbarPageTextStyle"
              href="https://raled.org/index.php/conferences"
            >
              Journal
            </a>
            <Link
              onClick={() => styleClick1()}
              className={
                active1
                  ? 'navbarPageTextStyle withBorder'
                  : 'navbarPageTextStyle noBorder'
              }
              to="/Contact">
              Contact
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default CollapsibleExample
