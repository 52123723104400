import {service} from ".";

export default  {
    getAllTitle: () => service.get("/Title"),
    getAllSector: () => service.get("/Sector"),
    getAllWorkExperience: () => service.get("/Work_experience"),
    // getAllLanguages: () => service.get("/Language"),
    // getAllCitizinship: () => service.get("/Citezenship"),
    // post data
    postExperts: (data) => service.post("/Expert", data, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "n4j6lnZ^D%w5*@APV%7Q%FxQM"
    }})

}