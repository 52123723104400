import React from 'react'
import { useState, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { FileUploader } from './Uploader'
import { Preview } from './Preview'
import { useDispatch, useSelector } from 'react-redux'
import {
  setData,
} from '../../store/reducers/applyReducer'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import applyCandidate from '../../services/applyCandidate'
import axios from 'axios'
import Cintizinship from './Citizenship'
import LanguageChoose from './language'
import './Apply.css'

function Services() {
  //////////
  const [getTitleAll, setGetTitleAll] = useState([])
  const [getAllSector, setGetAllSector] = useState([])
  // const [getAllLanguages, setGetAllLanguages] = useState([])
  const [getAllExperiences, setGetAllExperiences] = useState([])
  // const [getAllCitezenship, setGetAllCitezenship] = useState([])

  // Start Personal Info
  const [persInfoSelect, setPersInfoSelect] = useState('')
  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  // Finish Personal Info

  // Start Professional Info
  const [profInfoSelect, setProfInfoSelect] = useState('')
  const [profSecondInfoSelect, setProfSecondInfoSelect] = useState('')
  const [profThirdInfoSelect, setProfThirdInfoSelect] = useState('')
  const [profFourInfoSelect, setProfFourInfoSelect] = useState('')
  // Finish Professional Info

  // Short Term
  const [shortTerm, setShortTerm] = useState('')

  const dispatch = useDispatch()
  const data = useSelector((state) => state.apply)

  const [files, setFiles] = useState([])

  const [tabbar1, setTabbar1] = useState(true)
  const [tabbar2, setTabbar2] = useState(false)
  const [tabbar3, setTabbar3] = useState(false)

  // tab 1 Section start
  const handleClick1 = () => {
    setTabbar1(true)
    setTabbar2(false)
    setTabbar3(false)
  }
  // Tab 1 Section Finished

  const handleClick2 = () => {
    if (
      persInfoSelect === '' ||
      firstName === '' ||
      email === '' ||
      lastName === '' ||
      phoneNumber === ''
    ) {
      setTabbar1(true)
      setTabbar2(false)
      setTabbar3(false)

      toast.warn('Please fill in the required information!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else {
      setTabbar1(false)
      setTabbar2(true)
      setTabbar3(false)
    }
  }
  // Tab 1 Sction Finished

  // Tab 3 Section Start

  const handleClick3 = () => {
    if (
      persInfoSelect === '' ||
      firstName === '' ||
      email === '' ||
      lastName === '' ||
      phoneNumber === ''
    ) {
      setTabbar1(true)
      setTabbar2(false)
      setTabbar3(false)

      toast.warn('Please fill in the required information!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }else if(
      profInfoSelect === '' ||
      profSecondInfoSelect === '' ||
      profThirdInfoSelect === '' ||
      profFourInfoSelect === '' ||
      shortTerm === ''
    ){ 
      setTabbar1(false)
      setTabbar2(true)
      setTabbar3(false)

      toast.warn('Please fill in the required information!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else {
      setTabbar1(false)
      setTabbar2(false)
      setTabbar3(true)

      dispatch(
        setData({
          title: persInfoSelect,
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: phoneNumber,
          sector: profInfoSelect,
          experience: profThirdInfoSelect,
          language: profSecondInfoSelect,
          cintizinship: profFourInfoSelect,
          projectDuration: shortTerm,
        }),
      )
    }
  }
  // Tab 3 Section Finished
  const onSuccess = (savedFiles) => {
    setFiles(savedFiles)
  }

  ///////////////////////////////////////////////
  useEffect(() => {
    //getAll
    applyCandidate
      .getAllTitle()
      .then((res) => {
        setGetTitleAll(res)
      })
      .catch((err) => {
        console.log(err)
      })
    // getAll Sectors
    applyCandidate
      .getAllSector()
      .then((res) => {
        setGetAllSector(res)
      })
      .catch((err) => {
        console.log(err)
      })
    // get all experiences
    applyCandidate
      .getAllWorkExperience()
      .then((res) => {
        setGetAllExperiences(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <div className="Root container">
      <div className="applyWrapperStyle">
        <Tabs selected={0}>
          <TabList>
            <p className="applyFirstBigText">Join  us</p>
            <p className="applySecondSmallText">
              Please fill out the application form to join <br /> our Experts
              team.{' '}
            </p>
            <Tab>
              <div
                onClick={() => handleClick1()}
                className={
                  tabbar1
                    ? 'tabbarBasic1 BasicTextColor'
                    : 'tabbarBasic2 BasicTextColor'
                }
              >
                <span className="tabNumberStyle">1</span> Personal Info
              </div>
            </Tab>
            <Tab>
              <div
                onClick={() => handleClick2()}
                className={
                  tabbar2
                    ? 'tabbarBasic1 BasicTextColor'
                    : 'tabbarBasic2 BasicTextColor'
                }
              >
                <span className="tabNumberStyle">2</span> Professional Info
              </div>
            </Tab>
            <Tab>
              <div
                onClick={() => handleClick3()}
                className={
                  tabbar3
                    ? 'tabbarBasic1 BasicTextColor'
                    : 'tabbarBasic2 BasicTextColor'
                }
              >
                <span className="tabNumberStyle">3</span> Attach CV
              </div>
            </Tab>
          </TabList>

          <div
            id="tabbar1"
            className={tabbar1 ? 'tab_bar_block' : 'tab_bar_none'}
          >
            <div className="insideFrame">
              <div className="container">
                <form>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label className="selectTitleInApply">Title</label>
                      <br />
                      <select
                        className="applySelectedStyle"
                        onChange={(e) => setPersInfoSelect(e.target.value)}
                        value={persInfoSelect}
                      >
                        <option selected defaultValue={'DEFAULT'}>
                          Choose
                        </option>
                        {getTitleAll.map((item, index) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </select>{' '}
                      <br />
                      <label className="selectTitleInApply">First name</label>
                      <br />
                      <input
                        type="text"
                        className="applyInputStyle"
                        placeholder="Name"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                      />
                      <br />
                      <label className="selectTitleInApply">
                        E-mail address
                      </label>
                      <br />
                      <input
                        type="email"
                        className="applyInputStyle"
                        placeholder="Enter email address"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label className="selectTitleInApply selectTitleInApply1">
                        Last name
                      </label>
                      <br />
                      <input
                        type="text"
                        className="applyInputStyle"
                        placeholder="Write text"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                      />

                      <label className="selectTitleInApply">Phone number</label>
                      <input
                        type="text"
                        className="applyInputStyle"
                        placeholder="Enter phone number"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        value={phoneNumber}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="buttonSrtleDivInApply">
              <button
                onClick={() => handleClick2()}
                type="submit"
                className="serviceButtonStyle"
              >
                Next
              </button>
            </div>
          </div>

          <div
            id="tabbar2"
            className={tabbar2 ? 'tab_bar_block' : 'tab_bar_none'}
          >
            <div className="insideFrame">
              <div className="container">
                <form>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label className="selectTitleInApply">Sector</label>
                      <br />
                      <select
                        className="applySelectedStyle"
                        onChange={(e) => setProfInfoSelect(e.target.value)}
                        value={profInfoSelect}
                      >
                        <option selected defaultValue={'DEFAULT'}>
                          Choose
                        </option>
                        {getAllSector.map((item, index) => {
                          return <option value={item?.id}>{item?.name}</option>
                        })}
                      </select>

                      <label className="selectTitleInApply">
                        Spoken language(s)
                      </label>
                      <br />
                      <select
                        className="applySelectedStyle"
                        onChange={(e) =>
                          setProfSecondInfoSelect(e.target.value)
                        }
                        value={profSecondInfoSelect}
                      >
                        <option selected defaultValue={'DEFAULT'}>
                          Choose
                        </option>
                        {LanguageChoose.map((item) => (
                          <option value={item.value}>{item.name}</option>
                        ))}
                      </select>
                      <div className="allRadiosStyleLaptopApply">
                        <label className="selectTitleInApply">
                          Preffered project duration
                        </label>{' '}
                        <br />
                        <div className="form-check form-check-inline applyRadioStyle">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="Short term"
                            onChange={(e) => setShortTerm(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            Short term{' '}
                          </label>
                        </div>
                        <div className="form-check form-check-inline applyRadioStyle">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="Long term"
                            onChange={(e) => setShortTerm(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="option1"
                            htmlFor="inlineRadio2"
                          >
                            Long term
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label className="selectTitleInApply">
                        Work experience since
                      </label>
                      <br />
                      <select
                        className="applySelectedStyle"
                        onChange={(e) => setProfThirdInfoSelect(e.target.value)}
                        value={profThirdInfoSelect}
                      >
                        <option selected defaultValue={'DEFAULT'}>
                          Choose
                        </option>
                        {getAllExperiences.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </select>{' '}
                      <br />
                      <label className="selectTitleInApply">Citizenship</label>
                      <br />
                      <select
                        className="applySelectedStyle"
                        onChange={(e) => setProfFourInfoSelect(e.target.value)}
                        value={profFourInfoSelect}
                      >
                        <option selected defaultValue={'DEFAULT'}>
                          Choose
                        </option>
                        {Cintizinship.map((item) => (
                          <option value={item.value}>{item.name}</option>
                        ))}
                      </select>
                      <div className="allRadiosStyleLaptopApply2">
                        <label className="selectTitleInApply">
                          Preffered project duration
                        </label>{' '}
                        <br />
                        <div className="form-check form-check-inline applyRadioStyle">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="option1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            Short term{' '}
                          </label>
                        </div>
                        <div className="form-check form-check-inline applyRadioStyle">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="option2"
                          />
                          <label
                            className="form-check-label"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="option1"
                            htmlFor="inlineRadio2"
                          >
                            Long term
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <button
              onClick={() => handleClick3()}
              type="button"
              className="serviceButtonStyle"
            >
              Next
            </button>
          </div>

          <div
            id="foore"
            className={
              tabbar3
                ? 'tab_bar_block tabpanel3Apply'
                : 'tab_bar_none tabpanel3Apply'
            }
          >
            <div className="insideFrame tabPanelStyle">
              <FileUploader onClick onSuccess={onSuccess} />
              <Preview files={files} />
            </div>
          </div>
        </Tabs>
      </div>
    </div>
  )
}
export default Services

class Tabs extends React.Component {
  state = { selected: this.props.selected }

  setSelected(selected) {
    if (selected !== this.state.selected) {
      this.setState({ selected })
    }
  }

  handleClick(tab) {
    return () => this.setSelected(tab)
  }

  renderTabList(child) {
    let tab = 0

    return React.cloneElement(child, {
      children: React.Children.map(child.props.children, (childTab) => {
        if (childTab.type.name === 'Tab') {
          const _isActive = tab === this.state.selected
          const _onClick = this.handleClick(tab)

          tab++

          return React.cloneElement(childTab, { _isActive, _onClick })
        }

        return childTab
      }),
    })
  }

  renderChildren(children) {
    let panel = 0

    return React.Children.map(children, (child) => {
      if (child.type.name === 'TabList') {
        return this.renderTabList(child)
      }

      if (child.type.name === 'TabPanel') {
        const _isActive = panel === this.state.selected

        panel++

        return React.cloneElement(child, { _isActive })
      }

      return child
    })
  }

  render() {
    return (
      <div className="Tabs">{this.renderChildren(this.props.children)}</div>
    )
  }
}

const TabList = ({ children }) => <ul className="TabList">{children}</ul>

const Tab = ({ _onClick, _isActive, children }) => (
  <li className={`Tab  ${_isActive ? 'is-active' : ''}`} onClick={_onClick}>
    {children}
  </li>
)
