import './Experts.css';
import React from "react";
// import ExpertsData from './db';
import {data} from './db.js';
import CardTitle from './CardTitle.js';

function Experts() {
  return (
    <div className="expertsWrapper">
      <div className="container">
        <div className="expertsTitleName">
          OUR EXPERTS
        </div>
        <div className="cardExpertsClassNameMT">
          <div className="row">
            {
              data.map((posts) =>

                <div className="col-sm-12 col-md-4 col-lg-4" key={posts.id}>
                  <CardTitle posts={posts} />
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experts;
