const LANGUAGE_CHOOSE = [
    {
        value: "english",
        name: "English"
    },
    {
        value: "russian",
        name: "Russian"
    },
    {
        value: "uzbek",
        name: "Uzbek"
    },
]
export default LANGUAGE_CHOOSE;