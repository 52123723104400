import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uploadFileAction } from '../../store/reducers/applyReducer'
import applyCandidate from '../../services/applyCandidate'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './Upload.css'

export const FileUploader = ({ onSuccess }) => {
  const [file, setFile] = useState(null)
  const [fileNameUpload, setFilenameUpload] = useState('Drag and Drop your CV here');
  const dispatch = useDispatch()
  
  const handleChange = (e) => {
    if (e.target.files['0']) {
      const image = URL.createObjectURL(e.target.files['0'])
      toast.info('Your file easly saved, plese click submit send to us!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setFile(e.target.files['0'])
    }
  }


  const reduxStore = useSelector((state) => state.apply.data)
  const handleClick = async (e) => {
    e.preventDefault()
    const expertData = new FormData()
    expertData.append('first_name', reduxStore?.firstName)
    expertData.append('last_name', reduxStore?.lastName)
    expertData.append('email ', reduxStore?.email)
    expertData.append('phone ', reduxStore?.phoneNumber)
    expertData.append('prefer_project_duration ', reduxStore?.projectDuration)
    expertData.append('cv_file', file)
    expertData.append('title ', reduxStore?.title)
    expertData.append('sector ', reduxStore?.sector)
    expertData.append('work_experience  ', reduxStore?.experience)
    expertData.append('spoken_languages  ', reduxStore?.language)
    expertData.append('citizenship', reduxStore?.cintizinship)
    applyCandidate
      .postExperts(expertData)
      .then((res) => {
        toast.success('Successfully Loaded', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
      .catch((err) => {
        console.log(err)
        toast.error('Upload Failed', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }

  return (
    <form method="post" action="#" >
      {/* <div className="form-group files" onChange={(e) => console.log(file?.name)}> */}
      <div className="form-group files" onChange={(e) => setFilenameUpload(file?.name)}>
        <div className="inputDivStyle">
          <p className="uploadDragText">{ file ? file?.name + " Successfully Uploaded" : "Drag and Drop your CV here" }</p>
          <p className="uploadChooseFileStyle">Choose file</p>
          <input
            type="file"
            className="form-control"
            multiple
            onChange={(e) => handleChange(e)}
          />
          <div className="d-grid gap-2 d-md-block applyBtnMtAndMb">
              <button 
              onClick={() => window.location.reload(false)} 
              className="uploadCVApplyPageBtn2" 
              type="button"
              >
              Previous
            </button>
            <button
              onClick={
                (e) => { handleClick(e);  setTimeout(() => {
                  window.location.reload(false)
                }, 2500);}
              }
              className="uploadCVApplyPageBtn"
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}
